import * as React from 'react'
import { Page } from '../components/page'
import { graphql, PageProps } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Teaser } from '../components/teaser'
import { Content } from '../components/content'
import ReactMarkdown from 'react-markdown'
import { Seo } from '../components/seo'

const LegalPage = ({ data }: PageProps<Queries.LegalPageQuery>) => {
    const { t }: { t: any } = useTranslation()
    return (
        <Page pageName={'LEGAL'}>
            <Seo title={t('PAGES.LEGAL.HEADER')} />
            <Teaser pageName={'LEGAL'} image={'/images/teasers/austin-chan-ukzHlkoz1IE-unsplash.jpg'}></Teaser>
            <Content>
                <h2>{t('PAGES.LEGAL.HEADER')}</h2>
                <p>
                    {data.site?.siteMetadata?.contact?.name}
                    <br />
                    <br />
                    {data.site?.siteMetadata?.contact?.street}
                    <br />
                    {data.site?.siteMetadata?.contact?.zip}{' '}
                    {data.site?.siteMetadata?.contact?.city}
                    <br />
                    <br />
                    <b>{t('PAGES.LEGAL.EMAIL')}:</b>{' '}
                    {data.site?.siteMetadata?.contact?.email}
                </p>
            </Content>
        </Page>
    )
}

export default LegalPage

export const query = graphql`
    query LegalPage($language: String!) {
        site {
            siteMetadata {
                title
                contact {
                    name
                    street
                    zip
                    city
                    email
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
